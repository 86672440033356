<template>
  <div class="view profile-groups container-fluid" style="height: calc(100vh - 56px);">

    <div class="container-sm mt-5" style="max-width: 400px;">

      <div class="row" id="profile-box" @keyup.enter="onSave">
        <div class="col p-4" style="background: #ecf2a2">

          <h4 class="mb-2">Grupid</h4>
          <h6>Et aidata kaasa laste andmete turvalisusele, on loomine ja kasutamine viis kuidas lapsed saavad keskkonda
            kasutada ilma, et peaksid jagama enda isikuandmeid.</h6>
          <br>
          <h6 class="my-2"> Lapsed saavad sisse logida kasutajanime ja parooliga mille neile sisestate
            alljärgnevalt</h6>

          <h6 class="my-4">Lastel on hiljem võimalus oma kasutaja seadetes parool endale sobivaks muuta.</h6>

          <div v-if="dataStore.state.isUser">

            <h4 class="mt-5 mb-2">Halda Gruppe</h4>
            <hr class="mb-5">


            <h6 class="mt-5 mb-2" v-if="groupsList.length === 0">Hetkel teil ei ole ühtegi aktiivset gruppi <br> loo uus
              alt!</h6>
            <div class="group-box" v-else v-for="(group, index) in groupsList" :key="index">

              <h5 class="mt-5 mb-2">{{ group.name }}</h5>

              <table class="table" :style="{ background: getIndexedColor(index) }">
                <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Kasutajanimi</th>
                  <th scope="col">parool</th>
                  <th scope="col">
                    <button type="button" class="rounded-pill btn btn-sm btn-outline-danger"
                            @click="onDeleteGroup(group.name)">
                      Kustuta Grupp
                    </button>
                  </th>
                </tr>
                </thead>

                <tbody>
                <tr v-for="(user, userIndex) in group.users" :key="userIndex">
                  <th scope="row">{{ userIndex + 1 }}</th>
                  <td>{{ user }}</td>
                  <td>*******</td>
                  <td>
                    <button type="button" class="rounded-pill btn btn-sm btn-outline-danger"
                            @click="onRemoveUser(group.name, user)">
                      Eemalda
                    </button>
                  </td>
                </tr>

                <tr>
                  <th scope="row">{{ group?.users?.length + 1 }}</th>
                  <td>
                    <label>
                      <input v-model="group.newUsername" placeholder="Kasutajanimi" style="width: 100%;"
                             @keyup.enter="onAddUser(index)"/>
                    </label>
                  </td>
                  <td>
                    <label>
                      <input v-model="group.newPassword" placeholder="Parool" style="width: 100%;"
                             @keyup.enter="onAddUser(index)"/>
                    </label>
                  </td>
                  <td>
                    <button type="button" class="rounded-pill btn btn-sm btn-outline-primary"
                            @click="onAddUser(index)">
                      Lisa
                    </button>
                  </td>
                </tr>

                </tbody>
              </table>

            </div> <!-- End of group-box -->

            <h4 class="m-3 mt-5">Loo uus grupp</h4>
            <hr class="mb-3">

            <div class="row justify-content-center">
              <div class="col-7 p-0">
                <label class="my-2">
                  <input v-model="newGroupName" placeholder="nt. Nurmenukud" style="width: 100%;"
                         @keyup.enter="onNewGroup()"/>
                </label>
              </div>
              <div class="col-5 p-0">
                <button type="button" class="rounded-pill btn p-2 btn-outline-primary"
                        @click="onNewGroup()">
                  Lisa Grupp
                </button>
              </div>
            </div>

          </div>

          <h4 class="m-3 mt-5">Olen liige</h4>

          <hr class="mb-3">

          <div class="rounded-pill btn p-2 m-2" v-for="(group,index) in dataStore.state.groups"
               :key="index" :style="{background: getIndexedColor(index)}" style="pointer-events: none;">
            {{ group }}
          </div>

        </div>
      </div>

    </div>

  </div>
</template>

<script lang="ts">
import {defineComponent, reactive, inject, ref} from 'vue';
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import Api from "@/core/Api";
import {useToast} from "vue-toastification";


export default defineComponent({
  name: 'ProfileGroups',
  components: {},
  setup(props, {attrs, slots, emit}) {
    const dataStore: any = inject('dataStore');
    const colorArray = ['#FFA882', '#83DAD6', '#CBAACB', '#66AB8C', '#6EC4DB', '#FA7C92'];
    const groupsList = ref([]);
    const newGroupName = ref('');

    const state = reactive({
      isUser: dataStore.state.userRoles.some((role: string) => {
        return role === 'user';
      })
    })

    const getGroupData = async () => {
      groupsList.value = await Api.getGroupData();
    }
    getGroupData();

    const getIndexedColor = (index: number) => {
      const pos = index % colorArray.length;
      return colorArray[pos];
    }

    const onNewGroup = async () => {
      if (!!newGroupName.value) {
        await Api.addGroup(newGroupName.value)
            .then((data: any) => {
              newGroupName.value = '';
              getGroupData();
              useToast().success('Uus grupp lisatud');
            })
            .catch((err: any) => {
              if (err.response.data.message === 'Duplicate name') useToast().error('Nimi juba võetud, palun valige uus nimi');
              else useToast().error('Viga uue gruppi lisamisel')
            });
      } else {
        useToast().error('Vigane uue gruppi sisend, palun lisage grupi nimi');
      }
    }

    const onDeleteGroup = async (groupName: string) => {
      await Api.removeGroup(groupName)
          .then((data: any) => {
            getGroupData();
            useToast().success('Grupp eemaldatud');
          })
          .catch(() => useToast().error('Viga Gruppi eemaldamisel'));
    }

    const onAddUser = async (index: number) => {
      const groupObject: any = groupsList.value[index];
      if (!!groupObject.newUsername && !!groupObject.newPassword) {

        const newUserData = {
          groupName: groupObject.name, // todo, check if this is correct
          username: groupObject.newUsername,
          password: groupObject.newPassword
        };
        await Api.addGroupUser(newUserData)
            .then((data: any) => {
              groupObject.newUsername = '';
              groupObject.newPassword = '';
              getGroupData();
            })
            .catch((err: any) => {
              if (err.response.data.message === 'Duplicate user') useToast().error('Kasutaja nimi juba võetud, palun valige uus nimi');
              else useToast().error('Viga kasutaja lisamisel')
            });
      } else {
        useToast().error('Vigane uue kasutaja sisend, palun sisestage kasutajanimi koos parooliga');
      }
    }

    const onRemoveUser = async (groupName: string, userName: string) => {
      const userData = {userName, groupName};
      await Api.removeGroupUser(userData)
          .then((data: any) => {
            getGroupData();
          })
          .catch(() => useToast().error('Viga kasutaja eemaldamisel'));
    }

    return {
      state, dataStore, onNewGroup, getIndexedColor, groupsList,
      onAddUser, onDeleteGroup, onRemoveUser, newGroupName,
    }
  }
});
</script>

<style scoped lang="scss">

#profile-box {
  box-shadow: 2px 2px 10px 3px rgba(0, 0, 0, 0.5);
}

</style>
