
import {defineComponent, reactive, inject, ref} from 'vue';
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import Api from "@/core/Api";
import {useToast} from "vue-toastification";


export default defineComponent({
  name: 'ProfileGroups',
  components: {},
  setup(props, {attrs, slots, emit}) {
    const dataStore: any = inject('dataStore');
    const colorArray = ['#FFA882', '#83DAD6', '#CBAACB', '#66AB8C', '#6EC4DB', '#FA7C92'];
    const groupsList = ref([]);
    const newGroupName = ref('');

    const state = reactive({
      isUser: dataStore.state.userRoles.some((role: string) => {
        return role === 'user';
      })
    })

    const getGroupData = async () => {
      groupsList.value = await Api.getGroupData();
    }
    getGroupData();

    const getIndexedColor = (index: number) => {
      const pos = index % colorArray.length;
      return colorArray[pos];
    }

    const onNewGroup = async () => {
      if (!!newGroupName.value) {
        await Api.addGroup(newGroupName.value)
            .then((data: any) => {
              newGroupName.value = '';
              getGroupData();
              useToast().success('Uus grupp lisatud');
            })
            .catch((err: any) => {
              if (err.response.data.message === 'Duplicate name') useToast().error('Nimi juba võetud, palun valige uus nimi');
              else useToast().error('Viga uue gruppi lisamisel')
            });
      } else {
        useToast().error('Vigane uue gruppi sisend, palun lisage grupi nimi');
      }
    }

    const onDeleteGroup = async (groupName: string) => {
      await Api.removeGroup(groupName)
          .then((data: any) => {
            getGroupData();
            useToast().success('Grupp eemaldatud');
          })
          .catch(() => useToast().error('Viga Gruppi eemaldamisel'));
    }

    const onAddUser = async (index: number) => {
      const groupObject: any = groupsList.value[index];
      if (!!groupObject.newUsername && !!groupObject.newPassword) {

        const newUserData = {
          groupName: groupObject.name, // todo, check if this is correct
          username: groupObject.newUsername,
          password: groupObject.newPassword
        };
        await Api.addGroupUser(newUserData)
            .then((data: any) => {
              groupObject.newUsername = '';
              groupObject.newPassword = '';
              getGroupData();
            })
            .catch((err: any) => {
              if (err.response.data.message === 'Duplicate user') useToast().error('Kasutaja nimi juba võetud, palun valige uus nimi');
              else useToast().error('Viga kasutaja lisamisel')
            });
      } else {
        useToast().error('Vigane uue kasutaja sisend, palun sisestage kasutajanimi koos parooliga');
      }
    }

    const onRemoveUser = async (groupName: string, userName: string) => {
      const userData = {userName, groupName};
      await Api.removeGroupUser(userData)
          .then((data: any) => {
            getGroupData();
          })
          .catch(() => useToast().error('Viga kasutaja eemaldamisel'));
    }

    return {
      state, dataStore, onNewGroup, getIndexedColor, groupsList,
      onAddUser, onDeleteGroup, onRemoveUser, newGroupName,
    }
  }
});
